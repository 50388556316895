/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-13 18:42:34
 * @Module: 我的钱包
 */
 <template>
  <div class="wallet-box">
    <div class="wallet-box-t">
      <div class="title">
        <span>金币余额：</span>
        <span>{{order}}</span>
      </div>
      <div class="content">
        <div class="content-title">金币充值</div>
        <div class="content-box"
             v-if="goldList">
          <div class="content-item hover"
               :class="{'content-item-ac':item.uuid==active}"
               @click="active = item.uuid"
               v-for="(item,index) in goldList"
               :key="index">
            <p class="first">
              <span style="font-size:20px;">¥</span>{{item.price}}
            </p>
            <p class="last">
              <img src="@/static/homepage/金币支付.png"
                   style="display: inline;width:16px;height:16px;margin-right:3px;">
              {{item.amount}}金币
            </p>
            <div class="triangle"
                 v-show="item.uuid==active">
              <span class="el-icon-check icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px;background: #F7F7F7;" />
    <div class="wallet-box-b">
      <div class="content-title">支付方式 <div style="float:right;font-size:14px;color:#262626;"
             class="hover"
             @click="showAccount">我的账单</div>
      </div>
      <div class="play">
        <div class="play-item">
          <div class="play-item-l">
            <img src="@/static/homepage/支付宝.png"
                 style="width:32px;height:32px;">
            <p>支付宝支付</p>
          </div>
          <el-radio></el-radio>
        </div>
      </div>
    </div>
    <div class="wallet-box-btn">
      <div>需付金额：</div>
      <div>
        <span>¥</span>{{money}}
      </div>
      <div class="hover"
           @click="to_aliplay">立即支付</div>
    </div>
    <div v-html="h" />
    <account-module ref="accountModule" />
  </div>
</template>
 <script>
import accountModule from "@/views/college/module/accountModule"
export default {
  components: {
    accountModule
  },
  data () {
    return {
      goldList: [],
      active: 0,
      order: 0,
      h: ''
    };
  },
  mounted () {
    this._get_GlodList()
    this._get_orderDetail()
  },
  computed: {
    money () {
      return this.goldList.length ? this.goldList.find(item => item.uuid == this.active).price : 0
    },
  },
  methods: {
    showAccount () {
      this.$refs.accountModule.showModule()
    },
    async _get_orderDetail () {
      const { status, data } = await this.$api.orderOrderDetailApi()
      if (status == 200) {
        this.order = data
      }
    },
    async _get_GlodList () {
      const { status, data } = await this.$api.goodsGlodCoinApi()
      if (status == 200) {
        this.goldList = data
        this.active = data[0].uuid
      }
    },
    async _get_aliPlay (params) {
      const { status, data } = await this.$api.aliPlayApi(params)
      if (status == 200) {
        this.h = data
        this.$nextTick(() => {
          document.forms[0].submit()
        })
      }
    },
    to_aliplay () {
      /*
      product_id //产品uuid
      product_type //产品类型 1为课程 2为金币
      pay_type // 0支付宝 1微信 
      */

      const product_type = 2,
        pay_type = 0,
        product_id = this.active
      this._get_aliPlay({
        product_id,
        product_type,
        pay_type,
        quit_url: "localhost:8080/homepage/notice/wallet"
      })
      // const { href } = this.$router.resolve({
      //   path: "/aliplay",
      //   query: 
      // });
      // window.open(href, '_blank');//打开新的窗口
    }
  },
};
 </script>
 <style lang='scss' scoped>
.wallet-box {
  &-t {
    background: #fff;
  }
  &-b {
    background: #fff;
    // margin-top: 10px;
  }
  &-btn {
    background: #fff;
    padding-top: 36px;
    padding-bottom: 224px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    :nth-child(1) {
      font-size: 16px;
      color: #666666;
      margin-top: 23px;
      line-height: 22px;
    }
    :nth-child(2) {
      font-size: 32px;
      color: #ef504e;
      margin-top: 17px;
      span {
        color: #ef504e;
      }
      margin-left: 10px;
      margin-right: 30px;
    }
    :nth-child(3) {
      font-size: 16px;
      color: #fff;
      line-height: 46px;
      text-align: center;
      width: 180px;
      height: 46px;
      background: linear-gradient(90deg, #f15453 0%, #df2a29 100%);
      border-radius: 24px;
      margin-right: 20px;
      margin-top: 14px;
    }
  }
  .title {
    line-height: 58px;
    margin: 0 20px;
    border-bottom: #eee 1px solid;
    color: #262626;
    font-size: 18px;
    :last-child {
      color: #ff0000;
    }
  }
  .content {
    &-title {
      line-height: 70px;
      margin: 0px 20px;
      font-size: 20px;
      color: #262626;
    }
    &-box {
      padding: 0 20px 20px 20px;
      display: flex;
      flex-wrap: wrap;
      :nth-child(3n) {
        margin-right: 0px;
      }
    }
    &-item,
    &-item-ac {
      width: 270px;
      height: 140px;
      border: 2px solid #e3e3e3;
      border-radius: 4px;
      margin-right: 17px;
      margin-bottom: 20px;
      transition: all 0.5s;
      .first {
        margin-top: 40px;
        text-align: center;
        font-size: 26px;
        color: #666666;
        line-height: 28px;
      }
      .last {
        font-size: 16px;
        margin-top: 2px;
        line-height: 16px;
        color: #999;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &-item-ac {
      border-radius: 6px 6px 0 6px;
      border: 2px solid #df2a29;
      box-shadow: 1px 3px 8px 0px rgba(223, 42, 41, 0.1);
      position: relative;
      .first,
      .last {
        color: #df2a29;
      }
      .triangle {
        width: 0;
        height: 0;
        border-bottom: 30px solid #df2a29;
        border-left: 30px solid transparent;
        position: absolute;
        right: -1px;
        bottom: 0px;
        .icon {
          color: #fff;
          margin-left: -16px;
          margin-top: 13px;
        }
      }
    }
  }
  .play {
    &-item {
      width: 844px;
      height: 120px;
      background: rgba(223, 42, 41, 0.01);
      box-shadow: 1px 3px 8px 0px rgba(223, 42, 41, 0.1);
      border-radius: 6px;
      border: 2px solid #df2a29;
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding: 0 30px;
      justify-content: space-between;
      &-l {
        display: flex;
        align-items: center;
        p {
          margin-left: 12px;
        }
      }
    }
  }
}
</style>